<template>
  <div>
    <b-spinner
      v-if="loading"
      variant="primary"
      label="Spinning"
      class="position-absolute"
      style="top:50%; left:48%;"
    />
    <b-card v-else>
      <validation-observer ref="companyCreate">
        <b-form>
          <b-row>
            <b-col md="12">
              <div class="section-block mb-4">
                <div class="section-block__title">
                  <feather-icon
                    icon="PackageIcon"
                    width="24"
                    height="24"
                  />
                  <h2>Company details</h2>
                </div>

                <div
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label="Company Name"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Company Name"
                      vid="name"
                      rules="required|max:255"
                    >
                      <b-form-input
                        v-model="form.name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Company Name"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Company Registered Number"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Registered Number"
                      vid="registered_number"
                      rules="max:255"
                    >
                      <b-form-input
                        v-model="form.registered_number"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Registered Number"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label="VAT ID"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="VAT ID"
                      vid="vat_id"
                      rules="max:255"
                    >
                      <b-form-input
                        v-model="form.vat_id"
                        :state="errors.length > 0 ? false:null"
                        placeholder="VAT ID"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Currency"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Currency "
                      vid="currency"
                    >
                      <v-select
                        v-model="form.currency"
                        :options="currencies"
                        :reduce="currency => currency.id"
                        label="name"
                        :searchable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </div>
                <b-form-group
                  label="Telephone"
                  label-for="phone_number"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Telephone"
                    vid="phone_number"
                    rules="numeric|min:5|max:15|required"
                  >
                    <vue-tel-input
                      id="phone_number"
                      ref="phoneNumber"
                      v-model="form.phone_number"
                      class="form-control pl-0"
                      name="phone_number"
                      :preferred-countries="['GB']"
                      :auto-default-country="false"
                      :auto-format="false"
                      :default-country="defaultIso"
                      :dropdown-options="{
                        showDialCodeInSelection: true,
                        showFlags: true,
                        showDialCodeInList: true
                      }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Email"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="max:255|required|email"
                  >
                    <b-form-input
                      v-model="form.email"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Email"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Web"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Web"
                    vid="web"
                    rules="max:255|url"
                  >
                    <b-form-input
                      v-model="form.web"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Web"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  v-if="this.isShowTypesOptions"
                  label="Entity Type"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Entity Type "
                    vid="type_id"
                    rules="required"
                  >
                    <v-select
                      v-model="selectedCompanyType"
                      :options="types"
                      :reduce="type => type"
                      label="name"
                      :searchable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="showParentCompany"
                  label="Select parent company"
                  label-for="parent-company"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Parent company"
                    vid="company_id"
                  >

                    <v-select
                      v-model="form.parent_id"
                      :options="parentCompanyList"
                      :reduce="parent_id => parent_id.id"
                      placeholder="Type to search..."
                      label="name"
                      @search="onSearchParentCompany($event, selectedCompanyType.parent_id)"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Billing Terms "
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Billing Terms "
                    vid="billing_term"
                  >
                    <v-select
                      v-model="form.billing_term"
                      :options="billingTerms"
                      :reduce="billing_term => billing_term.id"
                      label="name"
                      :searchable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="section-block mb-4">
                <div class="section-block__title">
                  <feather-icon
                    icon="BookOpenIcon"
                    width="24"
                    height="24"
                  />
                  <h2>Address details</h2>
                </div>
                <p>Billing address:</p>
                <div class="px-50">

                  <div
                    class="neighbouring-form-container"
                  >
                    <b-form-group
                      label="Address Line 1*"
                      label-for="billing-address1"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Address Line 1"
                        vid="billing_address1"
                        rules="max:255|required"
                      >
                        <b-form-input
                          id="billing-address1"
                          v-model="form.billing_address1"
                          name="billing-address1"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Address Line 1"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- address2 -->
                    <b-form-group
                      label="Address Line 2"
                      label-for="billing-address2"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Address Line 2"
                        vid="billing_address2"
                        rules="max:255"
                        class="neighbouring-form-group"
                      >
                        <b-form-input
                          id="billing-address2"
                          v-model="form.billing_address2"
                          name="billing-address2"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Address Line 2"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                  </div>
                  <!-- Country -->
                  <b-form-group
                    label="Country*"
                    label-for="billing-country"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Country"
                      vid="billing_country_id"
                      rules="max:255|required"
                    >

                      <v-select
                        v-model="form.billing_country_id"
                        :options="countries"
                        :reduce="country_id => country_id.id"
                        placeholder="Select country"
                        label="name"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- region -->
                  <b-form-group
                    label="Region/State"
                    label-for="billing-region"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Region/State"
                      vid="billing_state"
                      rules="max:255"
                    >
                      <b-form-input
                        id="billing-region"
                        v-model="form.billing_state"
                        name="billing-region"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Region/State"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <div
                    class="neighbouring-form-container"
                  >
                    <!-- city -->
                    <b-form-group
                      label="Town/City*"
                      label-for="billing-city"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Town/City"
                        vid="billing_city"
                        rules="required|max:255"
                      >
                        <b-form-input
                          id="billing-city"
                          v-model="form.billing_city"
                          name="billing-city"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Town/City"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- postcode -->
                    <b-form-group
                      label="Postcode/Zip Code*"
                      label-for="billing-postcode"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Postcode/Zip Code"
                        vid="billing_postcode"
                        rules="max:255|required"
                      >
                        <b-form-input
                          id="billing-postcode"
                          v-model="form.billing_postcode"
                          name="billing-postcode"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Postcode/Zip Code"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
                <b-form-group>
                  <b-form-checkbox
                    id="isSameAddress"
                    v-model="isSameAddress"
                    name="checkbox-1"
                    :value="1"
                    :unchecked-value="0"
                  >
                    Shipping address same as billing
                  </b-form-checkbox>
                </b-form-group>
                <p v-if="!isSameAddress">
                  Shipping address:
                </p>
                <div
                  v-if="!isSameAddress"
                  class="px-50"
                >

                  <div
                    class="neighbouring-form-container"
                  >
                    <b-form-group
                      label="Address Line 1*"
                      label-for="shipping-address1"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Address Line 1"
                        vid="shipping_address1"
                        rules="max:255|required"
                      >
                        <b-form-input
                          id="shipping-address1"
                          v-model="form.shipping_address1"
                          name="shipping-address1"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Address Line 1"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- address2 -->
                    <b-form-group
                      label="Address Line 2"
                      label-for="shipping-address2"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Address Line 2"
                        vid="shipping_address1"
                        rules="max:255"
                      >
                        <b-form-input
                          id="shipping-address2"
                          v-model="form.shipping_address2"
                          name="shipping-address2"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Address Line 2"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <!-- Country -->
                  <b-form-group
                    label="Country*"
                    label-for="shipping-country"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Country"
                      vid="shipping_country_id"
                      rules="max:255|required"
                    >

                      <v-select
                        v-model="form.shipping_country_id"
                        :options="countries"
                        :reduce="country_id => country_id.id"
                        placeholder="Select country"
                        label="name"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- region -->
                  <b-form-group
                    label="Region/State*"
                    label-for="shipping-region"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Region/State"
                      vid="shipping_state"
                      rules="max:255"
                    >
                      <b-form-input
                        id="shipping-region"
                        v-model="form.shipping_state"
                        name="shipping-region"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Region/State"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <div
                    class="neighbouring-form-container"
                  >
                    <!-- city -->
                    <b-form-group
                      label="Town/City*"
                      label-for="shipping-city"
                      class="neighbouring-form-group"
                      label-class="label-class"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Town/City"
                        vid="shipping_city"
                        rules="required|max:255"
                      >
                        <b-form-input
                          id="shipping-city"
                          v-model="form.shipping_city"
                          name="shipping-city"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Town/City"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- postcode -->
                    <b-form-group
                      label="Postcode/Zip Code*"
                      label-for="shipping-postcode"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Postcode/Zip Code"
                        vid="shipping_postcode"
                        rules="max:255|required"
                      >
                        <b-form-input
                          id="shipping-postcode"
                          v-model="form.shipping_postcode"
                          name="shipping-postcode"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Postcode/Zip Code"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <b-form-group>
                    <b-form-checkbox
                      id="isActive"
                      v-model="form.is_active"
                      name="checkbox-1"
                      :value="1"
                      :unchecked-value="0"
                    >
                      Is active
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
              <div class="section-block mb-4">
                <div class="section-block__title">
                  <feather-icon
                    icon="MailIcon"
                    width="24"
                    height="24"
                  />
                  <h2>Invite primary admin</h2>
                </div>
                <div class="px-50">
                  <b-form-group
                    label="Email"
                    label-for="invite_email"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      vid="invite_email"
                      rules="required|max:255"
                    >
                      <b-form-input
                        id="invite_email"
                        v-model="form.invite_email"
                        name="invite_email"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
            </b-col>
            <!-- submit button -->
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                :disabled="isSavingActionInProgress"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BSpinner, BFormCheckbox,
} from 'bootstrap-vue'
import {
  required, max, integer, email, url,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import { mapActions, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

const VueTelInput = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input'),
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input/dist/vue-tel-input.css'),
// eslint-disable-next-line no-shadow
]).then(([{ VueTelInput }]) => VueTelInput)
export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BSpinner,
    vSelect,
    VueTelInput,
  },
  data() {
    return {
      loading: false,
      isShowTypesOptions: false,
      isSameAddress: false,
      selectedCompanyType: null,
      isSavingActionInProgress: false,
      form: {
        name: null,
        registered_number: null,
        vat_id: null,
        billing_term: null,
        currency: null,
        phone_number: null,
        email: null,
        web: null,
        type_id: null,
        parent_id: null,
        billing_address1: '',
        billing_address2: '',
        billing_country_id: null,
        billing_postcode: '',
        billing_state: '',
        billing_city: '',
        shipping_address1: '',
        shipping_address2: '',
        shipping_country_id: null,
        shipping_postcode: '',
        shipping_state: '',
        shipping_city: '',
        is_active: 1,
        invite_email: '',
      },
      required,
      max,
      integer,
      email,
      url,
    }
  },
  computed: {
    ...mapState('companyManagement', {
      billingTerms: 'billingTerms',
      countries: 'countries',
      currencies: 'currencies',
      types: 'types',
      defaultIso: 'defaultIso',
    }),
    ...mapFields('invite', [
      'parentCompanyList',
    ]),
    showParentCompany() {
      return !!(this.selectedCompanyType && this.selectedCompanyType.parent_id && this.selectedCompanyType.is_show_company)
    },
  },
  watch: {
    defaultIso(val) {
      this.$refs.phoneNumber.choose(val)
    },
    // eslint-disable-next-line func-names
    selectedCompanyType(newVal, prevVal) {
      this.fetchParentCompanyList({ query: '', id: newVal.parent_id })
      if (prevVal && newVal.id !== prevVal.id) {
        this.form.parent_id = null
      }
    },
  },
  async mounted() {
    this.loading = true
    this.getOptions().then(() => {
      this.loading = false
      this.isShowTypesOptions = !!this.types.filter(type => type.is_show_company).length
    })
  },
  methods: {
    ...mapActions('companyManagement', [
      'getOptions',
      'createItem',
    ]),
    ...mapActions('invite', [
      'fetchParentCompanyList',
    ]),
    onSearchParentCompany(query, id) {
      if (!query) {
        return
      }
      this.fetchParentCompanyList({ query, id })
    },
    async validationForm() {
      const valid = await this.$refs.companyCreate.validate()
      if (valid) {
        try {
          this.isSavingActionInProgress = true
          let payload = { ...this.form }

          delete payload.type_id
          if (this.selectedCompanyType?.id) {
            payload.type_id = this.selectedCompanyType.id
          }
          payload.phone_country_iso = this.$refs.phoneNumber.phoneObject.country.iso2
          payload.phone_country_code = this.$refs.phoneNumber.phoneObject.country.dialCode
          if (!payload.billing_term) {
            delete payload.billing_term
          }
          if (!payload.currency) {
            delete payload.currency
          }
          if (this.isSameAddress) {
            payload = {
              ...payload,
              shipping_address1: this.form.billing_address1,
              shipping_address2: this.form.billing_address2,
              shipping_country_id: this.form.billing_country_id,
              shipping_postcode: this.form.billing_postcode,
              shipping_state: this.form.billing_state,
              shipping_city: this.form.billing_city,
            }
          }
          await this.createItem(payload)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'company-list' })
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Validation error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
            this.$refs.companyCreate.setErrors(fields)
          }
        } finally {
          this.isSavingActionInProgress = false
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Validation error',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
